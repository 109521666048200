// 引入 axios
import axios from "axios";

const env = window['$env'] ? window['$env'] : process.env.NODE_ENV
console.log(env)

let base = env=='development'? 'https://www.daodaoming.com' : 'http://www.daodaomingketang.top';

//传送json格式的get请求
export const getRequest=(url,params)=>{
    return axios({
        method:'get',
        url:`${base}${url}`,
        data: params,
    })
}
